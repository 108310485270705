<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <h2>{{ $t("dashboard.MySubscriptions") }}</h2>
      <div>
        <b-button variant="primary" class="" @click="redirectToPricing()">
          {{ $t("dashboard.Upgrade_Plan") }}
        </b-button>
      </div>
    </div>
    <!-- search input -->
    <div class="">
      <div class="row">
        <!-- <b-col cols="12" md="5">
          <b-button
            variant="primary"
            class="mt-1 mb-3"
            @click="redirectToPricing()"
          >
            {{ $t("dashboard.Upgrade_Your_Plan") }}
          </b-button>
        </b-col> -->
        <!-- <b-col cols="12" md="7">
          <div class="d-flex justify-content-end">
            <b-form-group>
              <div class="">
                <label class="mr-1"> {{ $t("common.Search") }}</label>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('common.Search')"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col> -->
      </div>
    </div>

    <!-- language Search -->
    <!-- table -->
    <vue-good-table
      :columns="$store.state.locale.locale == 'ar' ? columnsArabic : columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{
            $store.state.locale.locale == "ar"
              ? props.row.arPlanName
              : props.row.planName
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'price'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{
            props.row.amountTotal + " " + props.row.plan.currency
          }}</span>
        </span>

        <!-- <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.plan.currency }}</span>
        </span> -->

        <span v-else-if="props.column.field === 'status'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">
            <b-badge pill variant="light-success">
              {{ props.row.paymentStatus }}
            </b-badge>
          </span>
        </span>

        <span
          v-else-if="props.column.field === 'startDate'"
          class="text-nowrap"
        >
          <span class="text-nowrap">
            {{ formatDate(props.row.startDate) }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'endDate'" class="text-nowrap">
          <span class="text-nowrap">
            {{ formatDate(props.row.endDate) }}
          </span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'isActive'" class="text-center">
          <b-badge :variant="statusVariant(props.row.isActive)">
            {{ formatActive(props.row.isActive) }}
          </b-badge>
        </span>

        <!-- <span
          v-else-if="props.column.field === 'createdAt'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ formatDate(props.row.createdAt) }}</span>
        </span> -->

        <!-- Column: Action -->
        <!-- <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="
                $router.push({
                  name: 'apps-invoice-preview',
                  // params: { id: data.item.id },
                })
              "
            />
            <b-tooltip title="Preview Invoice" />
          </span>
        </span> -->

        <!-- Column: Common -->
        <!-- <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span> -->
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t("common.Showing_to") }} </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ $t("common.of") }} {{ props.total }} {{ $t("common.entries") }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import moment from "moment";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BLink,
  BButton,
  BCol,
  BTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BLink,
    BButton,
    ToastificationContent,
    vSelect,
    BCol,
    BTooltip,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      languageSearch: null,
      columns: [
        {
          label: "Plan Name",
          field: "name",
        },
        {
          label: "Price",
          field: "price",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Start Date",
          field: "startDate",
        },
        {
          label: "End Date",
          field: "endDate",
        },
        {
          label: "Active",
          field: "isActive",
        },
      ],
      columnsArabic: [
        {
          label: "اسم الخطة",
          field: "name",
        },
        {
          label: "السعر",
          field: "price",
        },
        {
          label: "العملة",
          field: "currency",
        },
        {
          label: "الحالة",
          field: "status",
        },
        {
          label: "تاريخ البدأ",
          field: "startDate",
        },
        {
          label: "تاريخ النهاية",
          field: "endDate",
        },
        {
          label: "فعالة",
          field: "isActive",
        },

        // {
        //   label: "Action",
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      blogLanguage: [
        { label: "English", value: "en" },
        { label: "Arabic", value: "ar" },
      ],
    };
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        true: "light-primary",
        Professional: "light-success",
        false: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },

    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    // this.$http.get("/good-table/basic").then((res) => {
    //   this.rows = res.data;
    // });
    // axios
    //   .get("posts/allBlogs/")
    //   .then((response) => (this.rows = response.data.data));

    axios.get(`users/subscriptions/${this.user._id}`).then((response) => {
      // console.log(response.data.data.subscriptions);
      this.rows = response.data.data.subscriptions;
    });

    // this.getSubscribtions();
  },
  watch: {
    languageSearch: function () {
      this.getSubscribtions();
    },
  },

  methods: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
    formatActive(value) {
      if (value) {
        return "Active";
      } else {
        return "inActive";
      }
    },
    deleteBlog: async function (idBlog) {
      if (confirm("Are you sure you want to delete this Blog ?")) {
        axios.delete(`posts/${idBlog}`).then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.rows = this.rows.filter((data) => data._id != idBlog);

          // this.$vs.loading.close();

          // this.$router.push({ name: "blogs" });
        });
        // .then((response) => (this.rows = response.data.data));
      }
    },

    getSubscribtions() {
      axios
        .get(`users/subscriptions/${this.user._id}`)
        .then((response) => (this.rows = response.data));

      console.log(this.rows);
    },
    redirectToPricing() {
      let routeData = this.$router.resolve({
        name: "pages-pricing",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
</style>
<style scoped></style>
